// src/App.js
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from 'react-router-dom';

import styled from '@emotion/styled';

import Header from './components/Header';
import Ads from './components/Ads';
import RecruitmentScreen from './screens/RecruitmentScreen';
import { PATH_OTHERS, PATH_RECRUITMENT, PATH_ROOT } from './const/const';
import { BACKGROUND_COLOR_DARK_WHITE } from './const/constCss';

// 初期画面の設定
function InitRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    // 初期画面の設定
    navigate(PATH_RECRUITMENT);
  }, [navigate]);

  return null;
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <StyledApp className="app">
          <Header />
          <div className="main">
            <Routes>
              {/* 初期画面 */}
              <Route path={PATH_ROOT} element={<InitRedirect />} />

              {/* 全ての例外パスで下記スクリーンを描画 */}
              <Route
                path={PATH_OTHERS}
                element={<Navigate to={PATH_RECRUITMENT} />}
              />

              {/* recruitmentScreen */}
              <Route path={PATH_RECRUITMENT} element={<RecruitmentScreen />} />

              {/* スクリーンを追加する場合ここを書き換え */}
              <Route path="/test" element={<Ads />} />
            </Routes>
          </div>
        </StyledApp>
      </Router>
    </Provider>
  );
}

const StyledApp = styled.div`
  background-color: ${BACKGROUND_COLOR_DARK_WHITE};

  @media screen and (min-width: 768px) {
    .main {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      gap: 20px;
    }
  }
`;

export default App;
