// src/components/Header.jsx
import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Button } from '@mui/material';

import logo from '../img/Sketto-logo-wide.png';
import { IS_DEBUG, IS_DEBUG_HEADER_LOGOUT } from '../const/constDebug';
import { MATERIAL_BUTTON_WAIT_TIME } from '../const/const';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isLogin, setIsLogin] = useState(true);

  // ログイン状態を切り替える
  const login = () => {
    // ボタンのアニメーションが終了するまで待つ (例: 200ms)
    setTimeout(() => {
      setIsLogin(true);
    }, MATERIAL_BUTTON_WAIT_TIME);
  };

  // ログアウト処理
  const logout = () => {
    // ボタンのアニメーションが終了するまで待つ (例: 200ms)
    setTimeout(() => {
      setIsLogin(false);
    }, MATERIAL_BUTTON_WAIT_TIME);
  };

  // 会員登録画面へ遷移
  const signup = () => {
    // 会員登録画面へ遷移
  };

  return (
    <StyledHeaderBottomMargin
      className={`header ${
        (isLogin && IS_DEBUG && IS_DEBUG_HEADER_LOGOUT) || !isLogin
          ? 'header-bottom-margin'
          : ''
      }`}
    >
      <StyledHeaderLeft>
        <div className="logo">
          <img src={logo} alt="Sketto" />
        </div>
        <div>Skettoとは</div>
        <div>使い方</div>
      </StyledHeaderLeft>
      {!isLogin ? (
        <StyledHeaderButton>
          <ul>
            <li>
              <Button variant="outlined" color="primary" onClick={login}>
                ログイン
              </Button>
            </li>
            <li>
              <Button variant="outlined" color="primary" onClick={signup}>
                会員登録
              </Button>
            </li>
          </ul>
        </StyledHeaderButton>
      ) : (
        <StyledHeaderRight>
          <HeaderGlobalMenu>
            <ul>
              <li>
                <Link to="/recruitmentScreen">Screen 1</Link>
              </li>
              <li>
                <Link to="/test">Screen 2</Link>
              </li>
              <li>新着情報</li>
              <li>ユーザー情報</li>
              <li>募集設定</li>
              <li>やり取り</li>
              <li>設定</li>
            </ul>
          </HeaderGlobalMenu>
          <StyledHeaderHamburger>
            <span></span>
            <span></span>
            <span></span>
          </StyledHeaderHamburger>

          {IS_DEBUG && IS_DEBUG_HEADER_LOGOUT && (
            <StyledHeaderButton>
              <ul>
                <li>
                  <Button variant="outlined" color="primary" onClick={logout}>
                    ログアウト
                  </Button>
                </li>
              </ul>
            </StyledHeaderButton>
          )}
        </StyledHeaderRight>
      )}
    </StyledHeaderBottomMargin>
  );
};

const StyledHeader = styled.header`
  /* headerの縦幅に合わせ、比率は固定 */
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  background-color: #fff;

  /* 画面横幅に合わせる */
  width: 100%;
  box-sizing: border-box;

  /* 影を下につける */
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

  /* 上部で固定 */
  position: sticky;
  top: 0;
  z-index: 1;

  /* ヘッダーの下に来る要素を下げる */
  margin-bottom: 40px;
`;

const StyledHeaderBottomMargin = styled(StyledHeader)`
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 100%;
    max-width: 150px;
    max-height: 50px;
    object-fit: contain;
  }

  div {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeaderMenu = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    li {
      font-size: 14px;
      margin-right: 16px;
    }
  }
`;

const HeaderGlobalMenu = styled(StyledHeaderMenu)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledHeaderHamburger = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  span {
    width: 2rem;
    height: 0.25rem;
    background: #333;
    border-radius: 10px;
    position: relative;
    transform-origin: 1px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const StyledHeaderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }

  li {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    /* 画面が小さい時には一段下に下げて表示する */
    position: absolute;
    top: 60px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: #fff;

    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

    justify-content: center;

    ul {
      padding: 0;
      /* 小要素を左右に並べる */
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }

    li {
      display: flex; // ボタンを中央に配置するために追加
      margin-right: 0; // ボタンを中央に配置するために追加
      justify-content: center; // ボタンを中央に配置するために追加
      width: 100%;
    }

    Button {
      width: 70%;
    }
  }
`;

export default Header;
