// src/components/RecruitmentList.js
import React from 'react';
import Recruitment from './Recruitment';
import { Pagination } from '@mui/material';

const RecruitmentList = () => {
  // TODO MAUのページネーション

  return (
    <div className="list">
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Pagination
        count={11}
        defaultPage={1}
        shape="rounded"
        showFirstButton
        showLastButton
        // Router integrationを使いたい
      />
    </div>
  );
};

export default RecruitmentList;
