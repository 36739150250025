// src/const/constCss.js
// 色
export const BACKGROUND_COLOR_DARK_WHITE = '#f5f5f5';
export const BACKGROUND_COLOR_WHITE = '#fff';

export const SHADOW_COLOR_GRAY = 'rgba(0, 0, 0, 0.16)';

export const BORDER_COLOR = '#e0e0e0';

export const BUTTON_COLOR_A = '#44cbb1';

export const BUTTON_FONT_COLOR_WHITE = '#fff';

export const COMMON_COLOR_DARK_GRAY = '#333';

// フォントサイズ

export const FONT_SIZE_LARGE_1 = '20px';

export const FONT_SIZE_MEDIUM_1 = '14px';

export const FONT_SIZE_SMALL_1 = '12px';
