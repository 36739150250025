// src/const/const.js

// ボタンのアニメーションが終了するまで待つ (例: 200ms)
export const MATERIAL_BUTTON_WAIT_TIME = 200;

// パス
export const PATH_ROOT = '/';
export const PATH_OTHERS = '*';
export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_SIGNUP = '/signup';
export const PATH_RECRUITMENT = '/recruitment';
