// src/components/Ads.js
import React from 'react';

const Ads = () => {
  return (
    <div className="ads">
      {/* Advertisement will be here */}

      <div className="ads">
        <div className="ad">
          {/* 広告名 */}
          <div className="ad__title">広告名</div>
          {/* 広告の説明 */}
          <div className="ad__description">
            <p>広告の説明</p>
          </div>
          {/* 広告の画像 */}
        </div>
        <div className="ad">
          {/* 広告名 */}
          <div className="ad__title">広告名</div>
          {/* 広告の説明 */}
          <div className="ad__description">
            <p>広告の説明</p>
          </div>
          {/* 広告の画像 */}
        </div>
      </div>
    </div>
  );
};

export default Ads;
