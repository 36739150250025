// src/screens/RecruitmentScreen.jsx

import React, { useState } from 'react';
import { Pagination, Box, Tabs, Tab } from '@mui/material';
import Recruitment from '../components/Recruitment';
import RecruitmentList from '../components/RecruitmentList';
import Filter from '../components/Filter';
import Ads from '../components/Ads';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const RecruitmentScreen = () => {
  return (
    <>
      <Filter />
      <RecruitmentComponent />
      <Ads />
    </>
  );
};

const RecruitmentComponent = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="recruitmentListContainer">
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="アシスタント募集" />
          <Tab label="アシスタントしたい" />
        </Tabs>
      </Box>

      {/* タブの遷移先を設定 */}
      <TabPanel value={value} index={0}>
        <RecruitmentList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Component1 />
      </TabPanel>
    </div>
  );
};

function Component1() {
  // TODO MAUのページネーション

  return (
    <div>
      <h1>Component 1</h1>
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
      <Recruitment />
    </div>
  );
}

export default RecruitmentScreen;
