// src/components/Recruitment.js
import React from 'react';
// import './Recruitment.css';

const Recruitment = () => {
  return (
    <div className="recruitment">
      {/* 募集の絵リストを表示 */}
      <div className="recruitment__image">
        <img
          src="https://via.placeholder.com/150x150.png?text=150x150+Square"
          alt="150x150 Square"
        />
      </div>
      <div className="recruitment__content">
        <div className="recruitment__title">アシスタント募集</div>
        <div className="recruitment__description">
          <p>アシスタント募集します。一緒に働きましょう！</p>
        </div>
        <div className="recruitment__info">
          <div className="recruitment__info__item">
            <div className="recruitment__info__item__label">募集職種</div>
            <div className="recruitment__info__item__value">アシスタント</div>
          </div>

          <div className="recruitment__info__item">
            <div className="recruitment__info__item__label">勤務地</div>
            <div className="recruitment__info__item__value">東京都</div>
          </div>
          <div className="recruitment__info__item">
            <div className="recruitment__info__item__label">給与</div>
            <div className="recruitment__info__item__value">時給 1,000円</div>
          </div>
          <div className="recruitment__info__item">
            <div className="recruitment__info__item__label">勤務時間</div>
            <div className="recruitment__info__item__value">9:00 ~ 18:00</div>
          </div>
          <div className="recruitment__info__item">
            <div className="recruitment__info__item__label">募集期間</div>
            <div className="recruitment__info__item__value">
              2021/01/01 ~ 2021/01/31
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
