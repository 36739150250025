// src/components/Filter.js
import React from 'react';

const Filter = () => {
  return (
    <div className="filter">
      <input type="text" placeholder="Search..." />
      <button>Search</button>
      <button>Reset</button>
    </div>
  );
};

export default Filter;
